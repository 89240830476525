.header-openNav {
  position: fixed;
  width: 100%;
  height: 84vh;
  background-color: #ebebebea;
  box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.06);
  display: block;
  top: 111px;
  z-index: 150;
  scroll-behavior: initial;
}

.openNav-half-page-center {
  position: relative;
  width: 724px;
  background-color: white;
  z-index: 1000;
  overflow: auto;
  margin: auto;
  min-height: 100%;
  border-radius: 14px;
}



.header-openNav-home-page-null {
  position: relative;
  width: 930px;
  background-color: white;
  z-index: 1000;
  overflow: auto;
  margin: auto;
  min-height: 100%;
  top: 100px;
}

.openNav-half-page-center-mobile {
  position: relative;
  background-color: white;
  z-index: 1000;
  overflow: auto;
  margin: auto;
  min-height: 100%;
}

.header-openNav-home-page {
  position: absolute;
  width: 100%;
  height: 92vh;
  background-color: #ebebebea;
  box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.06);
  display: block;
  top: 58px;
  overflow: auto;
  z-index: 150;
  scroll-behavior: initial;
}

.header-openNav::-webkit-scrollbar {
  width: 9px;
  height: 1px;
  background-color: #e5e7e7;
}

.openNav-half-page-center::-webkit-scrollbar {
  width: 9px;
  height: 1px;
  background-color: #e5e7e7;
}

.openNav-half-page-center::-webkit-scrollbar {
  width: 9px;
  height: 1px;
  background-color: #e5e7e7;
}

.openNav-half-page-center-null::-webkit-scrollbar {
  width: 9px;
  height: 1px;
  background-color: #e5e7e7;
}

.header-openNav::-webkit-scrollbar-thumb {
  background: #798383;

}

.openNav-half-page-center::-webkit-scrollbar-thumb {
  background: #798383;
}

.openNav-half-page-center-null::-webkit-scrollbar-thumb {
  background: #798383;
}

.header-open-nav-icon {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  top: 43px;
  justify-content: center;
}

.header-open-nav-icon-mobile {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  justify-content: center;
}

.header-open-nav-icon-rotate {
  transform: rotate(180deg);
}

.header-open-nav-icon-text {
  position: absolute;
  width: 100%;
  text-align: center;
  font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
}

.header-open-nav-icon-text-empty {
  position: absolute;
  width: 400px;
  text-align: center;
  font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 35px;
}

.header-open-nav-icon-text-2 {
  position: absolute;
  width: 50%;
  text-align: center;
  top: 108px;
  font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 22px;
}

.header-open-nav-icon-text-3 {
  position: absolute;
  width: 50%;
  text-align: center;
  top: 137px;
  font-family: PFT_vilna, Mekorot-Vilna, sans-serif;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 22px;
}

.header-open-nav-icon-text-mobile {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 66px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 900;
  font-size: 38px;
  line-height: 85px;
}

.header-openNav-yoma {
  position: absolute;
  width: 100%;
  height: 90vh;
  background-color: rgb(255, 255, 255);
  box-shadow: 0 24px 28px 0 rgb(0 0 0 / 6%);
  display: block;
  top: 58px;
  overflow: auto;
  z-index: 150;
}

.header-openNavD {
  position: absolute;
  height: 90vh;
  width: 100%;
  background-color: white;
  box-shadow: 0 24px 28px 0 rgba(0, 0, 0, 0.06);
  overflow-x: scroll;
}

.header-openNav-top {
  position: fixed;
  z-index: 150;
  display: flex;
  z-index: 999999;
  background-color: white;
  width: 724px;
}

.header-openNav-top-home-page {
  width: 700px;
  min-height: 8%;
  position: fixed;
  z-index: 150;
  display: flex;
  align-items: center;
}

.header-openNav-top-mobile {
  width: 100%;
  min-height: 8%;
  background-color: white;
  position: fixed;
  z-index: 10;
  display: flex;
  align-items: center;
  top: 112px;
}

.header-openNav-top-closeSvg {
  width: 14px;
  height: 14px;
  position: relative;
  margin-right: 64px;
}

.openNav-text-wrapper {
  margin-top: 15px;
  width: 100%;
}

.openNav-top-item-background {
  font-family: Heebo;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.12px;
  text-align: center;
  color: #e4e4e4;
  cursor: pointer;
  border-radius: 8px;
  background: #38A09D;
  margin-right: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 15px;
}

.openNav-top-item-background-mobile {
  margin-right: 20px;
  font-size: 12px;
}

.openNav-top-item-background:hover {
  background-color: #e4e4e4;
  color: #38A09D;
}

.openNav-top-item-text {
  width: 89px;
  height: 28px;
  font-family: Heebo;
  font-size: 18.7px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.12px;
  text-align: center;
  color: #151515;
  cursor: pointer;
}

.openNav-top-item-divider {
  width: 8px;
  height: 28px;
  font-family: Heebo;
  font-size: 18.7px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.12px;
  text-align: center;
  color: #b7b7b7;
  margin-right: 9px;
  margin-left: 9px;
}

.openNav-talmud {
  margin-right: 0px;
  white-space: nowrap;
}

.header-hidden {
  display: none;
}

.openNav-main-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  height: 100px;
  top: 35px;
  left: 10px;
}

.openNav-main-container-mobile {
  display: flex;
  width: 100%;
  flex-direction: column;
  position: relative;
  height: 100px;
  left: 10px;
}

.openNav-choose-subject {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 147px;
  height: 70px;
  border-radius: 4px;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px #dfdfdf;
  background-color: #ffffff;
  font-family: Heebo;
  font-size: 22px;
  font-weight: normal;
  margin: 5px 11px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  color: #656565;
  cursor: pointer;
  box-sizing: border-box;
}

.openNav-choose-subject-water {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 147px;
  height: 70px;
  border-radius: 4px;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px #dfdfdf;
  background-color: #ffffff;
  font-family: Heebo;
  font-size: 22px;
  font-weight: normal;
  margin: 5px 11px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  color: #a7a7a7;
  cursor: pointer;
  box-sizing: border-box;
}

.openNav-choose-subject-mobile {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px #dfdfdf;
  background-color: #ffffff;
  font-family: Heebo;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  color: #151515;
  cursor: pointer;
  box-sizing: border-box;
  width: 140px;
  height: 63px;
  margin: 8px;
}

.color {
  color: #eae7e7;
}

.openNav-allbooks-container {
  width: 100%;
  justify-content: center;
  margin-top: 35px;
  position: relative;
  height: 100px;
}

.openNav-allbooks-container-ram {
  justify-content: center;
  margin-top: 6%;
  position: relative;
}

.openNav-allbooks-container-mobile {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 50px;

  width: 100%;
  justify-content: center;
  margin-top: 6%;
}

.openNav-allbooks-container-mobile-1 {
  display: block;
  flex-wrap: wrap;
  margin-bottom: 50px;
  width: 100%;
  justify-content: center;
  margin-top: 6%;
}

.allbooks-list-header {
  margin-top: 0px;
  height: 34px;
  font-family: Heebo;
  font-size: 20px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
  color: #575757;
  margin-bottom: -10px;
}

.allbooks-list-header-ram {
  height: 34px;
  font-family: Heebo;
  font-size: 20px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
  color: #575757;
  margin-bottom: -10px;
}

.openNav-book-item-Prophets {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 135px;
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px #dfdfdf;
  background-color: #ffffff;
  font-family: Heebo;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
  color: #656565;
  margin: 10px;
  cursor: pointer;
  box-sizing: border-box;
}

.openNav-book-item-SmallMas {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: 78px;
  border-radius: 4px;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px #dfdfdf;
  background-color: #ffffff;
  font-family: Heebo;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
  color: #656565;
  margin: 10px;
  cursor: pointer;
  box-sizing: border-box;
}

.openNav-book-item-Commentary {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 175px;
  height: 100px;
  border-radius: 4px;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px #dfdfdf;
  background-color: #ffffff;
  font-family: Heebo;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
  color: #656565;
  margin: 10px;
  cursor: pointer;
  box-sizing: border-box;
}

.openNav-book-item-Commentary-text {
  width: 155px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.color {
  color: #eae7e7;
}

.sss {
  width: 285px;
}

.openNav-book-item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: 48px;
  border-radius: 4px;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px #dfdfdf;
  background-color: #ffffff;
  font-family: Heebo;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
  color: #656565;
  margin: 5px 5px;
  cursor: pointer;
  box-sizing: border-box;
}

.openNav-book-item-ram {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  height: 80px;
  border-radius: 4px;
  box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.13);
  border: solid 2px #dfdfdf;
  background-color: #ffffff;
  font-family: Heebo;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
  color: #656565;
  margin: 5px 3px;
  cursor: pointer;
  box-sizing: border-box;
}

.mobile-1 {
  width: 125px;
}

.mobile-2 {
  width: 125px;
}

.openNav-allbooks-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
  left: 22px;
}


.openNav-allbooks-list-mobile-1 {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 50px;
  list-style: none;
  padding: 0;
  position: relative;
  justify-content: center;
}

.openNav-allbooks-list-Prophets {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
  justify-content: center;
}

.openNav-allbooks-list-Commentary {
  display: flex;
  flex-wrap: wrap;
  width: 850px;
  margin-bottom: 30px;
}

.openNav-allbooks-list-yoma {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
}

.openNav-allbooks-list-Prophets-mobile {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;
}



.openNav-allbooks-list-container-ram {
  margin: 0 10px;
}

.openNav-bookChosen-container {
  display: flex;
  flex-wrap: wrap;
  width: 620px;
  margin-bottom: 50px;
  justify-content: center;
}

.bookChosen-page-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 65px;
  height: 65px;
  border-radius: 4px;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.13);
  border: solid 1px #dfdfdf;
  background-color: #ffffff;
  margin: 5px;
  direction: rtl;
  cursor: pointer;
  box-sizing: border-box;
}

.bookChosen-page-item-introduction {
  width: 325px;
}

.bookChosen-page-item:hover {
  border: solid 2px #49ccc8;
  border-radius: 4px;
}

.bookChosen-current-item:hover {
  border: solid 2px #38A09D;
  border-radius: 4px;
}

.openNav-book-item:hover {
  border: solid 2px #16c4bf;
  border-radius: 4px;
}

.openNav-book-item-ram:hover {
  border: solid 2px #16c4bf;
  border-radius: 4px;
}

.openNav-book-item-current:hover {
  border: solid 2px #57b7ff;
  border-radius: 4px;
}

.openNav-choose-subject:hover {
  border: solid 2px #0fbab5;
  border-radius: 4px;
}

.openNav-bookChosen-container-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 4.5%;
  position: relative;
  z-index: 100;

}


.bookChosen-page-item-text {
  direction: ltr;
  min-height: 34px;
  margin-bottom: 0px;
  font-family: Heebo;
  font-size: 22.7px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
  color: #151515;
  margin: 0;
}

.openNav-bookChosen-container-header {
  height: 34px;
  font-family: Heebo;
  font-size: 23px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
  color: #151515;
}

.bookChosen-current-item {
  border: solid 2px #38A09D;
}

.openNav-book-item-current {
  border: solid 2px #38A09D;
}

.openNav-select-dropdown {
  font-family: Heebo, sans-serif;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.15px;
  text-align: center;
  color: #151515;
  background: none;
  width: 100%;
  margin-bottom: 30px;
  position: sticky;
}

.MuiTypography-root {
  position: relative;
}

.react-select-container {
  width: 200px;
  font-family: Heebo, sans-serif;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 18px;
  letter-spacing: 0.15px;
  text-align: center;
  color: #151515;
  margin-bottom: 15px;
}

.select-chapter-openNav {
  display: flex;
  justify-content: center;
}

.MuiCircularProgress-svg {
  color: #38A09D;
}

/*----------------------------------*/

.sfarim-search {
  position: relative;
  top: 2px;
  height: 40px;
  width: 724px;
  padding-top: 10px;
  overflow-y: scroll;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
  border: 1px solid #38A09D;
  box-sizing: border-box;
  border-radius: 8px;
  z-index: 1001;
  background: #ffffff;
}

.sfarim-search-input {
  height: 30px;
  border: white;
  right: 20px;
  position: absolute;
  top: 3px;
  font-family: Heebo;
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 31px;
  width: 479px;
}

input:focus {
  outline: none;
}

.sfarim-search-circle {
  position: absolute;
  left: 0%;
  border-radius: 40px;
  height: 40px;
  width: 40px;
  text-align: center;
  top: -1px;
  cursor: pointer;
}

.sfarim-search-circle:hover {
  background-color: #e4e8e8;
}

.sfarim-search-row {
  position: absolute;
  left: 3.6%;
  top: 37%;
}

@media screen and (max-width: 965px) {
  .openNav-text-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 850px) {
  .openNav-bookChosen-container {
    padding-top: 0px;
    width: 90%;
    justify-content: center;
  }
}

.openNav-main-container-empty {
  position: relative;
  height: 150px;
}

.openNav-main-container-empty-mobile {
  position: relative;
  height: 150px;
}