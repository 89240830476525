.react-select__control {
    position: relative !important;
    border-radius: 8px !important;
    width: 193px !important;
    background: #f8f8f8 !important;
}

.react-select__option:hover {
    background: #f8f8f8 !important;
}

.react-select__option--is-selected {
    background: #38A09D !important;
}

.react-select__control--is-focused {
    position: relative !important;
    border-radius: 8px !important;
    border-color: #38A09D !important;
    box-shadow: 0 0 0 1px #38A09D !important;
    width: 193px !important;
    height: 5px !important;
    border: 0px !important;
    background: #f8f8f8 !important;
}
